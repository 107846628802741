<template>
    <div>
        <div class="kt-portlet__head d-sm-block d-none" >
            <form class="isprint-filter" autocomplete="off">                
                <div class="form-group">
                    <label>Codice allenamento</label><br/>
                    <input class="form-control form-control-sm"  autocomplete="off" name="adasd" placeholder="Codice Allenamento" type="text" style="width:150px" v-model="search.CODICE_ARTICOLO.VALUE">
                </div>
                <div class="form-group">
                    <label>Descrizione</label><br/>
                    <input class="form-control form-control-sm"  autocomplete="off" name="adasd" placeholder="Descrizione" type="text" style="width:150px" v-model="search.DESCRIZIONE.VALUE">
                </div>       
                <button class="btn btn-success float-right" v-on:click="nuovoAllenamento()" style="margin-top:26px"><i class="fal fa-plus"></i> Nuovo</button>       
            </form>
        </div>
        <button class="btn btn-success btn-lg d-block d-sm-none btn-block" v-on:click="nuovoAllenamento()" style="margin-top:26px"><i class="fal fa-plus"></i> Nuovo</button>       

        <div class="isprint-grid-container mt-4" >    
            <div class="w-100 mt-2" v-for="settimana in arraySettimane" v-bind:key="settimana">
                <h3 style="border-bottom:1px solid #ccc;margin-bottom: 0px;">Settimana {{settimana.SETTIMANA}} - {{settimana.ANNO}} <button class="btn btn-grid btn-info float-right">Azioni</button></h3>
                <div class="w-100" style="overflow:auto; padding-top:20px;padding-bottom:20px;display: inline-block;">
                    <div class="col-md-6 p-0"  v-for="allenamento in settimana.ARRAY_ALLENAMENTI" v-bind:key="allenamento.ALLENAMENTO">
                        <div class="card shadow lift-panel" style=" height: 88px;margin-bottom:16px" v-on:click="openAllenamento(allenamento)">
                            <div class="card-body" style="min-width:200px">
                                <div class="row no-gutters align-items-center">
                                    <div class="col mr-2">
                                        <div class="font-weight-bold text-ecomm h4 mb-1">{{getGiornoSettimana(allenamento.GIORNO)}} {{ filters.formatDateNoYear(allenamento.DATA_ALLENAMENTO) }}</div>
                                        <div class="h6 mb-0 text-gray-800"><strong>{{ allenamento.DESCRIZIONE }}</strong></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>                    
                </div>
            </div>
        </div>


        <div class="modal fade" id="popUpAllenamento" data-backdrop="true" role="dialog"  aria-modal="true" >
            <div class="modal-dialog"   >
                <div class="modal-content" >
                    <div class="modal-header modal-header-primary">
                        <h4 style="color:#fff" class="modal-title"><i class="fad fa-clipboard-list-check fa-fw"></i> Allenamento</h4>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span>&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <form autocomplete="off">
                           <div class="row">
                                <div class="form-group col-12" >
                                   <label>Data</label>
                                   <DatePicker  v-model="allenamentoSelezionato.DATA_ALLENAMENTO" placeholer="Descrizione"></DatePicker>
                                </div>  
                                <div class="form-group col-12" >
                                   <label>Descrizione</label>
                                   <input type="text" class="form-control"  v-model="allenamentoSelezionato.DESCRIZIONE" placeholer="Descrizione">
                               </div> 
                               <div class="form-group col-12" >
                                   <label>Gruppo</label>
                                   <select  class="form-control"  v-model="allenamentoSelezionato.FK_ID_GRUPPO" placeholer="Descrizione">
                                        <option v-for="gruppo in $root.arrayGruppi" v-bind:key="gruppo.ID_GRUPPO" v-bind:value="gruppo.ID_GRUPPO">{{ gruppo.DESCRIZIONE }}</option>
                                   </select>
                               </div>  
                               <div class="form-group col-12" >
                                    <label>Note</label>
                                    <textarea type="text" class="form-control"  v-model="allenamentoSelezionato.NOTE" placeholer="Descrizione">
                                    </textarea>
                               </div>                    
                           </div>
                       </form>
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-success" v-on:click="salvaAllenamento()"><i class="fal fa-check"></i> Salva</button>
                    </div>
                </div>
            </div>
        </div>

        

    </div>
</template>
<script>

import DatePicker from '@/components/utils/DatePicker.vue'


export default {
        name:"Allenamenti",
        components:{
            DatePicker
        },
        data:function(){
            return{
                filters:global.filters,
                search:{
                    PAGINATION:{LENGTH:0,PAGE_NUMBER:1,PAGE_SIZE:50},
                    DESCRIZIONE:{TYPE:'TEXT',VALUE:''}, 
                    CODICE_ARTICOLO:{TYPE:'TEXT',VALUE:''}, 
                },
                searchCr:{
                    PAGINATION:{LENGTH:0,PAGE_NUMBER:1,PAGE_SIZE:18},
                    DESCRIZIONE:{TYPE:'TEXT',VALUE:''},
                },
                arrayAllenamenti : new Array(),
                arraySettimane : new Array(),
                arrayCentriRicavo : new Array(),
                allenamentoSelezionato : {serie:new Array()}
            }
        },
        methods: {
            getAllenamenti : function(){
                utils.get('/ns/allenamenti',{FK_ID_GRUPPO:this.$root.ID_GRUPPO_SELEZIONATO}, (response) => {
                    this.arraySettimane = new Array();
                    for (var i = 0 ; i < response.data.length ; i++) {
                        var check = true;
                        for (var j = 0 ; j < this.arraySettimane.length ; j++){
                            if (response.data[i].ANNO ==  this.arraySettimane[j].ANNO && response.data[i].SETTIMANA ==  this.arraySettimane[j].SETTIMANA){
                                check = false;
                            }
                        }
                        if (check == true){
                            this.arraySettimane.push({ANNO:response.data[i].ANNO,SETTIMANA:response.data[i].SETTIMANA , ARRAY_ALLENAMENTI:new Array()});
                        }
                    }
                    for (var j = 0 ; j < this.arraySettimane.length ; j++){
                        for (var i = 0 ; i < response.data.length ; i++) {
                            if (response.data[i].ANNO ==  this.arraySettimane[j].ANNO && response.data[i].SETTIMANA ==  this.arraySettimane[j].SETTIMANA){
                                this.arraySettimane[j].ARRAY_ALLENAMENTI.push(response.data[i]);
                            }
                        }
                    }
                });
            },   
            nuovoAllenamento : function(){
                this.allenamentoSelezionato = {ID_ALLENAMENTO:0,FK_ID_GRUPPO:this.$root.ID_GRUPPO_SELEZIONATO,DATA_ALLENAMENTO:this.filters.formatDate(new Date())};
                var modal = $('#popUpAllenamento');
                modal.modal('show');
            },
        
        
            salvaAllenamento : function(){
                const metodo = this.allenamentoSelezionato.ID_ALLENAMENTO > 0 ? utils.put : utils.post;
                metodo("ns/allenamento",this.allenamentoSelezionato,(response)=>{
                    utils.alert.success(response.messaggio);
                    var modal = $('#popUpAllenamento');
                    modal.modal('hide');
                    this.getAllenamenti();
                });
            },
            getDistanzaTotaleAllenamento : function(serie){
                var distanzaTotale = 0;
                for (var i = 0; i < this.allenamentoSelezionato.serie.length ; i++){
                    var serie = this.allenamentoSelezionato.serie[i];
                    var distanza = serie.DISTANZA_SINGOLA * serie.NUM_RIPETUTE;
                    if (!isNaN(distanza)){
                        distanzaTotale += distanza;
                    }
                }
                return this.filters.formattaImportoInt(distanzaTotale);  
            },
            openAllenamento : function(allenamento){
                this.$root.ID_ALLENAMENTO_SELEZIONATO = allenamento.ID_ALLENAMENTO;
                global.router.push("Allenamento");
            },
            getGiornoSettimana : function(idGiorno){
                return utils.getGiornoSettimana(idGiorno)['DESC_3'];
            }
        },
        computed : {
            paginatedList(){
                var arr = utils.inGrid(this.arrayAllenamenti,this.search);
                return arr;
            },
        
        },
        created : function(){
            this.getAllenamenti();  
        },
    }
</script>