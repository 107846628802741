<template>
    <div class="row" style="border-bottom: 1px solid #ccc;padding-bottom: 50px;margin-top: 20px;">  
        <div class="col-md-4">
          <div class="card shadow"   style="margin-top: 10px;height:108px">
              <div class="card-body">
                  <div class="row no-gutters align-items-center">
                      <div class="col mr-2">
                          <div class="font-weight-bold text-ecomm h3 mb-1" v-show="$root.APP == 'CONFSPORT'">Portale Gare {{$root.VERSIONE}}</div>
                          <div class="font-weight-bold text-ecomm h3 mb-1" v-show="$root.APP == 'NUOTOSPRINT'">NuotoSprint</div>

                          <div class="h6 mb-0  text-gray-800"><strong>Utente</strong> {{$root.utente.persona.COGNOME}} {{$root.utente.persona.NOME}}</div> 
                      </div> 
                      <div class="col-auto"><i class="fad fa-user fa-fw fa-4x" style="color:#0679ab;"></i>
                      </div>
                  </div>
              </div>
          </div>
      </div>
        <div class="col-md-4" v-show="$root.APP == 'CONFSPORT'">
            <div class="card shadow"  style="margin-top: 10px;height:108px">
                <div class="card-body">
                    <div class="row no-gutters align-items-center">
                        <div class="col mr-2">
                            <div class="font-weight-bold text-ecomm h3 mb-1">Ente</div>
                            <div class="h6 mb-0  text-gray-800">{{$root.TIPO_PORTALE == 1 ? 'C.N.S. LIBERTAS' : 'Confsport Italia apssd' }} </div> 
                        </div> 
                        <div class="col-auto"> 
                            <img src="~@/assets/img/libertas.png"  v-if="$root.APP == 'CONFSPORT' && $root.TIPO_PORTALE == 1" style="max-width: 100px;" class="img-responsive d-none d-sm-block">
                            <img src="~@/assets/img/confsport.png" v-if="$root.APP == 'CONFSPORT' && $root.TIPO_PORTALE == 2" style="max-width: 166px; margin-top: -8px;" class="img-responsive d-none d-sm-block">
                            <img src="~@/assets/img/nuotosprint.png" v-if="$root.APP == 'NUOTOSPRINT'" style="max-width: 100px;" class="img-responsive d-none d-sm-block">
                      </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-4 ">
          <div class="card shadow lift-panel"   style="margin-top: 10px;height:108px" >
              <div class="card-body">
                  <div class="row no-gutters align-items-center">
                      <div class="col mr-2">
                            <div class="font-weight-bold text-ecomm h3 mb-1">Società</div>
                            <div class="h6 mb-0  text-gray-800" >
                                <strong>{{ $root.utente.personaGiuridica.DENOMINAZIONE }}</strong>
                            </div> 
                           
                      </div> 
                      <div class="col-auto"><i class="fad fa-university  fa-fw fa-4x" style="color:#0679ab;"></i>
                      </div>
                  </div>
              </div>
          </div>
        </div>
    </div>
    <div class="row" style="margin-top:30px">
        <div class="col-md-3" style="height: 140px;"  v-if="$root.utente.FK_ID_LIVELLO == 1">
            <div class="card shadow lift-panel " v-on:click="openModulo('Amministrazione')" style="margin-top:20px;background:#4e73df;">
                <div class="card-body" >
                    <div class="row no-gutters align-items-center">
                        <div class="col mr-2">
                            <div class="font-weight-bold mb-1 h4" style="color:#fff">Amministrazione</div>  
                            <div class="h6 mb-0  " style="color:#fff" >Gestione amministrazione</div> 
                        </div>
                        <div class="col-auto">
                            <i class="fal fa-cogs fa-4x" v-if="$root.APP == 'CONFSPORT'" style="color:#fff"></i>
                            <i class="fad fa-cogs fa-4x" v-if="$root.APP == 'NUOTOSPRINT'" style="color:#fff"></i>
                        </div>
                    </div>
                </div>
            </div>        
        </div>
        
        
        <div class="col-md-3" style="height: 140px;" >
            <div class="card shadow lift-panel "    v-on:click="eventi()" style="margin-top:20px;background:#f4b30d;">
                <div class="card-body" >
                    <div class="row no-gutters align-items-center">
                        <div class="col mr-2">
                            <div class="font-weight-bold mb-1 h4"  style="color:#fff">Eventi</div>  
                            <div class="h6 mb-0  " style="color:#fff" >Ci sono {{ eventiAperti }} eventi aperti</div> 
                        </div>
                        <div class="col-auto">
                            <i class="fal fa-trophy fa-4x"  v-if="$root.APP == 'CONFSPORT'" style="color:#fff"></i>
                            <i class="fad fa-trophy fa-4x"  v-if="$root.APP == 'NUOTOSPRINT'" style="color:#fff"></i>
                        </div>
                    </div>
                </div>
            </div>        
        </div>

       

        <div class="col-md-3" style="height: 140px;"  v-if="$root.utente.FK_ID_LIVELLO == 1" >
            <div class="card shadow lift-panel "  v-on:click="openModulo('Utenti')" style="margin-top:20px;background: #69d0ff !important;">
                <div class="card-body" >
                    <div class="row no-gutters align-items-center">
                        <div class="col mr-2">
                            <div class="font-weight-bold mb-1 h4" style="color:#fff !important">Utenti</div>  
                            <div class="h6 mb-0  " style="color:#fff" >Visualizza e gestisci gli utenti</div> 
                        </div>
                        <div class="col-auto">
                            <i class="fal fa-euro-sign fa-4x" style="color:#fff"></i>
                        </div>
                    </div>
                </div>
            </div>        
        </div>

   
        <div class="col-md-3" style="height: 140px;"  v-if="$root.utente.FK_ID_LIVELLO != 1" >
            <div class="card shadow lift-panel "  v-on:click="openModulo('Ordini')" style="margin-top:20px;background: #69d0ff !important;">
                <div class="card-body" >
                    <div class="row no-gutters align-items-center">
                        <div class="col mr-2">
                            <div class="font-weight-bold mb-1 h4" style="color:#fff !important">Ordini</div>  
                            <div class="h6 mb-0  " style="color:#fff" >Visualizza e gestisci gli ordini di pagamento</div> 
                        </div>
                        <div class="col-auto">
                            <i class="fal fa-sign-in fa-4x" style="color:#fff"></i>
                        </div>
                    </div>
                </div>
            </div>        
        </div>


        <div class="col-md-3" style="height: 140px;"  v-if="$root.utente.FK_ID_LIVELLO != 1" >
            <div class="card shadow lift-panel "  v-on:click="openModulo('Portafoglio')" style="margin-top:20px;background: #17a673 !important;">
                <div class="card-body" >
                    <div class="row no-gutters align-items-center">
                        <div class="col mr-2">
                            <div class="font-weight-bold mb-1 h4" style="color:#fff !important">Portafoglio</div>  
                            <div class="h6 mb-0  " style="color:#fff" >Saldo corrente: € {{ filters.formattaImporto(saldoCorrente) }}</div> 
                        </div>
                        <div class="col-auto">
                            <i class="fal fa-wallet fa-4x" style="color:#fff"></i>
                        </div>
                    </div>
                </div>
            </div>        
        </div>

        
        <div class="col-md-3" style="height: 140px;"  v-if="$root.APP == 'NUOTOSPRINT'" >
            <div class="card shadow lift-panel " v-for="gruppo in $root.arrayGruppi" v-bind:key="gruppo.ID_GRUPPO"  v-on:click="$root.ID_GRUPPO_SELEZIONATO = gruppo.ID_GRUPPO; openModulo('Allenamenti')" style="margin-top:20px;background: #17a673 !important;">
                <div class="card-body" >
                    <div class="row no-gutters align-items-center">
                        <div class="col mr-2">
                            <div class="font-weight-bold mb-1 h4" style="color:#fff !important">Allenamenti</div>  
                            <div class="h6 mb-0  " style="color:#fff" >Gruppo <strong>{{ gruppo.DESCRIZIONE }}</strong></div> 
                        </div>
                        <div class="col-auto">
                            <i class="fad fa-clipboard-list-check fa-4x" style="color:#fff"></i>
                        </div>
                    </div>
                </div>
            </div>        
        </div>
        
   

       <!-- 
        <button class="btn btn-info" v-on:click="loginLibertas()">LOGIN</button>
        -->
    </div>

<!--
    <input type="text" class="form-control" v-model="tabella"><button class="btn btn-info" v-on:click="creaTabella()">Crea tabella</button>
-->
</template>
<script>



export default {
    name:"Dashboard",
    components: {
      
    },
    data:function(){
      return{
        utente:{personaGiuridica:{}},
        tabella:"",
        dataUltimoOrdine:"",
        filters:global.filters,
        eventiAperti : 0,
        saldoCorrente : 0
      }
    },
    methods:{
        creaTabella:function(){
            
        },
        openModulo : function(modulo){
            global.router.push(modulo);
        },
        eventi : function(){
            console.log(this.$root.APP);
            if (this.$root.APP == 'CONFSPORT'){
                console.log("passo uqi");
                global.router.push("Sport");
            }
            else{
                global.router.push("Eventi");
            }            
        },
        hasFunzionalita : function(id){
            return true;
            for (var i = 0 ; i < this.$root.utente.funzionalita.length ; i++){
                if (this.$root.utente.funzionalita[i].ID_FUNZIONALITA == id){
                    return true;
                }
            }
            return false;
        },
        loginLibertas : function(){


            utils.post('ns/libertas/login',{}, (response) => {
            
            });
        }
    },
    computed:{
        
    },
    created: function () {
        global.utils.ajax('core/home',{}, (response) => {            
            this.$root.utente = response.data.UTENTE;
            this.eventiAperti = response.data.EVENTI_APERTI;
            this.saldoCorrente = response.data.SALDO;
            this.$root.arrayRegioni = response.data.REGIONI;
            this.$root.arrayRegioni.splice(0,0,{ID_REGIONE:1000,NOME:"NAZIONALE"});
            this.$root.arrayGruppi = response.data.GRUPPI;
            this.$root.arrayIntensita = response.data.REGIMI;
            this.$root.arrayStili = response.data.STILI;
            this.$root.arrayModalita = response.data.MODALITA;
        });
    },
}
</script>